import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "financialButton", "financialPane", "financialSummary", "dataButton", "dataPane", "dataSummary" ]

  showFinancial() {
    this.financialButtonTarget.classList.add('bg-white')
    this.financialButtonTarget.classList.add('text-[#02152a]')
    this.financialButtonTarget.classList.remove('text-white/50')
    this.dataButtonTarget.classList.remove('bg-white')
    this.dataButtonTarget.classList.add('text-white/50')
    this.dataButtonTarget.classList.remove('text-[#02152a]')
    this.financialPaneTarget.classList.add('block')
    this.financialPaneTarget.classList.remove('hidden')
    this.dataPaneTarget.classList.add('hidden')
    this.dataPaneTarget.classList.remove('block')
    this.financialSummaryTarget.classList.add('block')
    this.financialSummaryTarget.classList.remove('hidden')
    this.dataSummaryTarget.classList.add('hidden')
    this.dataSummaryTarget.classList.remove('block')
  }

  showData() {
    this.financialButtonTarget.classList.remove('bg-white')
    this.financialButtonTarget.classList.remove('text-[#02152a]')
    this.financialButtonTarget.classList.add('text-white/50')
    this.dataButtonTarget.classList.add('bg-white')
    this.dataButtonTarget.classList.remove('text-white/50')
    this.dataButtonTarget.classList.add('text-[#02152a]')
    this.financialPaneTarget.classList.remove('block')
    this.financialPaneTarget.classList.add('hidden')
    this.dataPaneTarget.classList.remove('hidden')
    this.dataPaneTarget.classList.add('block')
    this.financialSummaryTarget.classList.remove('block')
    this.financialSummaryTarget.classList.add('hidden')
    this.dataSummaryTarget.classList.remove('hidden')
    this.dataSummaryTarget.classList.add('block')
  }
}
