// To see this message, follow the instructions for your Ruby framework.
//
// When using a plain API, perhaps it's better to generate an HTML entrypoint
// and link to the scripts and stylesheets, and let Vite transform it.
// console.log('Vite ⚡️ Ruby')

import { Application } from "@hotwired/stimulus"

import SolutionsController from "~/stimulus_controllers/solutions_controller.js";
import FaqController from "~/stimulus_controllers/faq_controller.js";

window.Stimulus = Application.start()
Stimulus.register("solutions", SolutionsController)
Stimulus.register("faq", FaqController)

// Example: Import a stylesheet in <sourceCodeDir>/index.css
// import '~/index.css'
