import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "faqPlus", "faqClose", "faqAnswer" ]

  showAnswer(event) {
    const element = event.target
    this.targets.findAll('faqPlus')[element.dataset.faqIndex].classList.toggle('hidden')
    this.targets.findAll('faqPlus')[element.dataset.faqIndex].classList.toggle('block')
    this.targets.findAll('faqClose')[element.dataset.faqIndex].classList.toggle('hidden')
    this.targets.findAll('faqClose')[element.dataset.faqIndex].classList.toggle('block')
    this.targets.findAll('faqAnswer')[element.dataset.faqIndex].classList.toggle('hidden')
    this.targets.findAll('faqAnswer')[element.dataset.faqIndex].classList.toggle('block')
  }
}
